import React, { useState } from "react";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import "./PropertyInfo.scss"
import Dateformat from "../../../Components/format-date"
import parse from 'html-react-parser';
import GetCTA from "../../getcta";
import { AltSiteName } from "../../utils";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import SocialShare from "../../../share";
const Property = (props) => {
    var newsdata = props.newsdata;
    let processedImages = JSON.stringify({});
    if (newsdata?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = newsdata.imagetransforms.Banner_Image_Transforms;
    }	
    return (
        <React.Fragment>

                <section className="about-property news-content">
                
                    <Container>
                        <Row>
                            <Col>
                                <div className="property-wrapper">
                                    <span className="date">
                                        <Dateformat date={newsdata.News_Published_Date}/>
                                    </span>
                                    <div className="heading">
                                        <h1>{newsdata.Title}</h1>
                                        <span className="icons">
                                            <SocialShare />                                            
                                        </span>
                                    </div>
                                    <div className="property-img img-zoom">
                                         <ImageTransform imagesources={newsdata.Banner_Image.url} renderer="srcSet" imagename="news-blogs.Banner_Image.commontile" attr={{ alt: newsdata.Banner_Image.alternativeText?newsdata.Banner_Image.alternativeText:newsdata.Title+AltSiteName}} imagetransformresult={processedImages} id={newsdata.id}/>                                    
                                         {newsdata?.Select_News_Category?.Name && <span className="category-tag">{newsdata?.Select_News_Category?.Name}</span>}
                                    </div>
                                    <div className="property-details">
                                       {parse(newsdata.Short_Intro)}
                                    </div>
                                    <div className="btn-wrapper">
                                    {newsdata.CTA_Below_Short_Intro && newsdata.CTA_Below_Short_Intro.length > 0 && newsdata.CTA_Below_Short_Intro.map((cta,index) => {
                                    return<>
                                        {index === 0 &&
                                        <>
                                        {cta.CTA_Custom_Link ?
                                        <Link to={cta.CTA_Custom_Link} className="btn btn-primary">{cta.CTA_Label}</Link>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn btn-primary" link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link ?
                                        <Link to={cta.CTA_Custom_Link} className="btn ">{cta.CTA_Label}</Link>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn " link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                    </>
                                    })}
                                    </div>

                                    <div className="view">
                                        
                                        {parse(newsdata.Full_Content)}
                                        
                                    </div>    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    
                </section>
        </React.Fragment>

    )
}
export default Property
