import React, { useState, useEffect } from 'react';
const DateFormat = (props) => {
	const d  = new Date(props.date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const we = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span className="date-text">{`${we} ${da} ${mo} ${ye}`}</span>
	)
}
export default DateFormat
