import React, { useState } from "react";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Card } from "react-bootstrap"
import { OtherNews } from "../../../queries/common_use_query";
import "./GalleryDetails.scss"
import DateFormat from "../../format-date";
import { NewsBaseURL, AltSiteName } from "../../utils";
import "../../../Components/Home/Insights/Insights.scss";
import Slider from "react-slick";

import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
const DetailsInfo = (props) => {
    const {loading:loading, error:error, data:data} = OtherNews(props.newsurl);
    const settings = {
        dots: false,
        speed: 800,
        infinite: true,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 3020,
            settings: {
              arrows: false,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              arrows: false,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 400,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };
    return (
        <React.Fragment>
            <section className="gallery-details d-none d-md-block">
                
                    <Container>
                        <Row>
                            <Col>
                                <h2>You may also like</h2>
                                <div className="property-details">
                                {data && data.newsBlogs.length > 0 && data.newsBlogs.map((item, index) => {
                                     let processedImages = JSON.stringify({});
                                     if (item?.imagetransforms?.Tile_Image_Transforms) {
                                     processedImages = item.imagetransforms.Tile_Image_Transforms;
                                     }
                                    return<>
                                    <div className="property-wrapper">
                                        <div className="property-img img-zoom">
                                            <Link to={`${NewsBaseURL}/${item.Select_News_Category?.URL}/${item.URL}`} className="img-link">
                                            <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>             
                                            {item?.Select_News_Category?.Name && <span className="category-tag">{item?.Select_News_Category?.Name}</span> }
                                            </Link>
                                        </div>
                                        <div className="property-info">
                                            <span className="date">
                                                <DateFormat date={item.News_Published_Date}/>
                                            </span>
                                            <h2>
                                            <Link to={`${NewsBaseURL}/${item.Select_News_Category?.URL}/${item.URL}`}>
                                                {item.Title}
                                            </Link>
                                            </h2>
                                        </div>

                                    </div>
                                    </>
                                })}                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
            </section>
        <section className="insights-row d-block d-md-none">
        <Container>
          <Row>
            <Col className="d-flex align-item-center">
              <div className="insights-title">
                <h2>Insights</h2>
              </div>
              <div className="ms-auto d-flex align-items-center">
                <Link to="/about/latest-property-news" className="link-text d-none d-lg-inline-block">
                  All news
                </Link>
                <Link to="/about/latest-property-news">
                <button type="button" className="btn-circle">
                  <i className="icon-arrow"></i>                
                </button>
                </Link>
              </div>
            </Col>
          </Row>
          <div className="insights-slider">
            <Slider className="banner-imgslider d-block" {...settings}>
            {data && data.newsBlogs.length > 0 && data.newsBlogs.map((item, index) => {
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = item.imagetransforms.Tile_Image_Transforms;
            }
            return<>
              <div className="insight-item">
                <Link to={`${NewsBaseURL}/${item.Select_News_Category?.URL}/${item.URL}`} className="img-zoom">
                <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>             
                </Link>
                <div className="insights-block">
                  <DateFormat date={item.News_Published_Date}/>
                  <h3>
                    <Link to={`${NewsBaseURL}/${item.Select_News_Category?.URL}/${item.URL}`}>
                      {" "}
                      {item.Title}
                    </Link>
                  </h3>
                </div>
              </div>
            </>
            })}
              
            </Slider>
          </div>
        </Container>
      </section>
        </React.Fragment>

    )
}
export default DetailsInfo
